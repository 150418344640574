(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name trek.controller:AppCtrl
   *
   * @description
   *
   */
  angular
    .module('trek')
    .controller('AppCtrl', ['$state', '$mdSidenav', 'AuthTokenFactory', 'Restangular', AppCtrl]);

  function AppCtrl($state, $mdSidenav, AuthTokenFactory, Restangular) {
    var vm = this;
    vm.ctrlName = 'AppCtrl';
    vm.currentMenu = '';
    vm.logout = logout;
    vm.toggleNav = toggleNav;
    vm.closeNav = closeNav;
    vm.toggleMenu = toggleMenu;
    vm.goTo = goTo;

    function logout() {
      AuthTokenFactory.setToken();
      vm.goTo('login.login');
    }

    function toggleNav() {
      $mdSidenav('left-nav').toggle();
    }

    function closeNav() {
      $mdSidenav('left-nav').close();
    }

    function toggleMenu(menuName) {
      vm.currentMenu = vm.currentMenu == menuName ? '' : menuName;
    }

    function goTo(stateName, params) {
      $state.go(stateName, params);

      // Collapse submenus if going to a different module
      if (vm.currentMenu.split('.')[0] != stateName.split('.')[0]) {
        vm.currentMenu = '';
      }

      closeNav();
    }
  }
}());
